"use client";

import {
  FC,
  useState,
  useEffect,
  useMemo,
} from "react";

import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { AriaBoolean } from "apps/website/maps/Aria.map";
import IconButton from "apps/website/components/base/IconButton/IconButton";
import List from "apps/website/components/base/List/List";
import Column from "apps/website/components/layout/Column/Column";
import useWindowSize from "apps/website/hooks/useWindowSize";
import Logo from "apps/website/components/base/Icon/Logo/Logo";
import Grid from "apps/website/components/layout/Grid/Grid";
import { useAuth } from "@auth/client-sdk-react";

import {
  borderlessSectionWidths,
} from "../../section/SectionIconTextList/Section.map";

import HeaderAuthItems from "./HeaderAuthItems/HeaderAuthItems";
import HeaderNavigationItem from "./HeaderNavigationItem/HeaderNavigationItem";
import {
  IHeaderAuth,
  IHeaderHistoryNavigation,
  IHeaderLinks,
} from "./Header.map";

export interface IHeader extends IHeaderLinks {
  className?: string;
  hasAnnouncementBanner?: boolean;
  historyNavigation?: IHeaderHistoryNavigation
  associatedDiscountCode?: string;
}

const Header: FC<IHeader> = (
  {
    primaryLinks,
    secondaryLinks,
    authLinks,
    guestLinks,
    className,
    hasAnnouncementBanner = true,
    historyNavigation,
  },
) => {

  const { windowSize, isActiveBreakpointAbove } = useWindowSize();

  const [ isMobileMenuOpen, setIsMobileMenuOpen ] = useState(false);
  const [ isMenuAriaHidden, setIsMenuAriaHidden ] = useState<AriaBoolean>("false");

  const { user } = useAuth();

  const auth = useMemo((): IHeaderAuth | undefined => (user?.firstName ? {
    firstName: user.firstName,
    lastName: user.lastName,
  } : undefined), [ user ]);

  useEffect(() => {
    const isMenuHidden = isActiveBreakpointAbove("lg") || isMobileMenuOpen ? "false" : "true";
    if (isMenuHidden !== isMenuAriaHidden) {
      setIsMenuAriaHidden(isActiveBreakpointAbove("lg") || isMobileMenuOpen ? "false" : "true");
    }
  }, [ windowSize, isMobileMenuOpen, isActiveBreakpointAbove ]);

  const computedClasses = `${themeRootClassMap.header} ${className}`;

  return (
    <>
      <header data-component="Header" className={`fixed top-0 w-full z-50 h-[50px] xl:h-[88px] flex items-center ${hasAnnouncementBanner && "xl:top-11"} ${computedClasses}`}>
        <div className={`h-full mx-auto max-w-[1600px] ${borderlessSectionWidths.default} ${borderlessSectionWidths.lg} ${borderlessSectionWidths.xl}`}>
          <Grid className="h-full">

            <Column spans={{ default: 4, lg: 5 }} justify="start" align="center" direction="row">
              { historyNavigation?.back ? (
                <>
                  { !historyNavigation.hide && (
                    <IconButton
                      icon="chevronLeft"
                      onClick={historyNavigation.back}
                      label="Go back"
                      size="xsmall"
                      className="flex-row-reverse"
                      textClassName="underline"
                      textSize="xs"
                      display="subtitle"
                    >
                  Back
                    </IconButton>
                  ) }
                </>
              ) : (
                <>
                  <div className="xl:hidden">
                    <IconButton
                      icon={isMobileMenuOpen ? "cross" : "menu"}
                      label={`${isMobileMenuOpen ? "Close" : "Open"} mobile navigation`}
                      onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                      size="small"
                    />
                  </div>
                  <nav
                    id="primary-navigation"
                    className={`
              fixed bg-light-grey xl:bg-transparent top-[50px] xl:static left-0 h-full w-full transition-transform duration-500 border-t border-t-black border-solid shadow-2xl ${isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"}
              xl:top-0 xl:h-auto xl:w-auto xl:translate-x-0 xl:border-t-0 xl:shadow-none bg-no-repeat bg-[center_bottom_4rem] bg-[url('/images/sections/animated-split/cat-chases-yarn.webp')] xl:bg-none
              pb-80 xl:pb-0
              overflow-auto xl:overflow-visible
            `}
                    style={{ backgroundSize: "150%" }}
                    aria-label="Primary navigation"
                    aria-hidden={isMenuAriaHidden}
                  >
                    <List direction={{ default: "column", xl: "row" }} className="container mx-auto xl:mx-0 xl:w-full mt-8 xl:mt-0">
                      { primaryLinks.map((item) => (
                        <HeaderNavigationItem key={item.name} item={item} isMenuAriaHidden={isMenuAriaHidden} />
                      )) }
                      { secondaryLinks.map((item) => (
                        <HeaderNavigationItem key={item.name} item={item} isMenuAriaHidden={isMenuAriaHidden} className="xl:hidden"/>
                      )) }
                      <HeaderAuthItems
                        auth={auth}
                        authLinks={authLinks}
                        guestLinks={guestLinks.filter((x) => x.name !== "Start my trial")}
                        menu="mobile" isHidden={isMenuAriaHidden === "true"}
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                        className={auth ? "order-first lg:hidden" : ""}
                      />
                    </List>
                  </nav>
                </>
              ) }
            </Column>
            <Column spans={{ default: 4, lg: 2 }} justify="center" align="center">
              <Logo onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} to={ auth ? "/customer" : "/"} className="w-[125px] xl:w-[180px] xl:transform xl:scale-[1.5] xl:translate-y-2"/>
            </Column>
            <Column spans={{ default: 4, lg: 5 }} align="center" justify="end" direction="row">
              <nav id="user-navigation" aria-label={`${(auth ? "User" : "Guest")} navigation`}>
                <List direction="row">
                  { secondaryLinks.map((item) => (
                    <HeaderNavigationItem key={item.name} item={item} isMenuAriaHidden={isMenuAriaHidden} className="hidden xl:flex"/>
                  )) }
                  <HeaderAuthItems
                    auth={auth}
                    authLinks={authLinks}
                    guestLinks={guestLinks}
                    menu="desktop"
                    isHidden={isMenuAriaHidden === "true"}
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  />
                </List>
              </nav>
            </Column>
          </Grid>
        </div>
      </header>
      <div className="h-[50px] xl:h-[88px] w-full"></div>
    </>
  );
};

export default Header;
