import { CustomerAPI } from "@api-clients/customer";
import { DiscountsAPI } from "@api-clients/discounts";
import { LitterAPI } from "@api-clients/litter";
import { FreshMealPlanAPI } from "apps/website/api";
import { CapiClient } from "@api-clients/capi";

import { PublicConfig } from "../config/public";
import { FormsActionsAPI } from "../api/FormsActions";
import { Statistics } from "../api/Statistics";
import { CheckoutAPI } from "../api/Checkout";
import { SurveyAnswers } from "../api/SurveyAnswers";

export interface IUseGetClient {
  Checkout: CheckoutAPI;
  FreshMealPlan: FreshMealPlanAPI;
  Litter: LitterAPI;
  Customer: CustomerAPI;
  FormActions: FormsActionsAPI;
  Capi: CapiClient;
  Statistics: Statistics;
  Discounts: DiscountsAPI;
  SurveyAnswers: SurveyAnswers;
  tokenStatus: {
    accessToken: string | undefined;
    loggedIn: boolean | undefined;
  };
}

export default function useGetClient(accessToken: string | undefined): IUseGetClient {
  return {
    Checkout: new CheckoutAPI(PublicConfig.NEXT_PUBLIC_CHECKOUT_URL, accessToken),
    FreshMealPlan: new FreshMealPlanAPI(PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL, accessToken),
    Litter: new LitterAPI(PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL, accessToken),
    FormActions: new FormsActionsAPI(PublicConfig.NEXT_PUBLIC_FORM_ACTIONS_URL, accessToken),
    Customer: new CustomerAPI(
      PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL,
      accessToken,
      { withCredentials: true },
    ),
    Discounts: new DiscountsAPI(
      PublicConfig.NEXT_PUBLIC_DISCOUNT_API_BASE_URL,
      accessToken,
    ),
    Capi: new CapiClient(
      PublicConfig.NEXT_PUBLIC_CAPI_BASE_URL,
      accessToken,
    ),
    Statistics: new Statistics(PublicConfig.NEXT_PUBLIC_STATISTICS_URL, accessToken),
    SurveyAnswers: new SurveyAnswers(PublicConfig.NEXT_PUBLIC_SURVEY_ANSWERS_BASE_URL, accessToken),
    tokenStatus: {
      accessToken,
      loggedIn: !!accessToken,
    },
  };
}
