"use client";

import {
  FC,
  ReactNode,
  useRef,
  useState,
} from "react";
import { Swiper } from "swiper/react";
import SwiperCore, {
  SwiperOptions,
  Grid,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper";

import {
  Breakpoint,
  tailwindBreakpointsMap,
} from "apps/website/maps/Breakpoint.map";
import useClient from "apps/website/hooks/useClient";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
import IconButton from "apps/website/components/base/IconButton/IconButton";

import {
  Overflow,
  Perspective,
  overflowClassMap,
  perspectiveClassMap,
  SpaceBetween,
  spaceBetweenMap,
  NavigationStyle,
  navigationStyleMap,
} from "./Carousel.map";
import styles from "./Carousel.module.css";

type Breakpoints = {
  [key in Breakpoint]?: number;
};
export interface ICarousel {
  slidesPerView?: number;
  slideCount?: number;
  breakpoints?: Breakpoints;
  loop?: boolean;
  overflow?: Overflow;
  perspective?: Perspective;
  centeredSlides?: boolean;
  spaceBetween?: SpaceBetween;
  showNavigation?: boolean;
  navigationStyle?: NavigationStyle;
  autoPlay?: boolean;
  reverseAutoplay?: boolean;
  component?: string;
  pagination?: boolean;
  rows?: number;
  children: ReactNode;
}

const Carousel: FC<ICarousel> = ({
  slidesPerView = 1,
  slideCount = 1,
  breakpoints = { default: 1 },
  loop = false,
  overflow = "default",
  perspective = "default",
  component = "Carousel",
  centeredSlides = false,
  spaceBetween = "default",
  pagination = true,
  showNavigation = true,
  navigationStyle = "default",
  autoPlay = false,
  reverseAutoplay = false,
  rows = 1,
  children,
}) => {

  const swiperRef = useRef<SwiperCore>();
  const [ isStart, setIsStart ] = useState<boolean | null>(null);
  const [ isEnd, setIsEnd ] = useState<boolean | null>(null);

  const { isClient } = useClient();

  const swiperBreakpoints = (): SwiperOptions["breakpoints"] => {
    const swiperBreakpointOptions: SwiperOptions["breakpoints"] = {};
    Object.entries(breakpoints).forEach(([ key, value ]) => {
      swiperBreakpointOptions[tailwindBreakpointsMap[
        key as keyof typeof tailwindBreakpointsMap
      ]] = { slidesPerView: value, grid: { rows } };
    });

    return swiperBreakpointOptions;
  };

  const computedClasses = [
    styles.carousel,
    perspectiveClassMap[perspective],
    overflowClassMap[overflow],
    autoPlay && styles.autoplay,
  ].join(" ");

  const autoPlayAgs = autoPlay ? { delay: 1 } : false;
  const paginationArgs = (pagination && showNavigation) ? { clickable: true } : false;
  const navigationArgs = navigationStyleMap[navigationStyle];
  const speed = autoPlay ? 10000 : 300;

  return (
    <div data-component={component} className={computedClasses} dir={reverseAutoplay ? "rtl" : "ltr"}>
      { isClient && (
        <Swiper
          speed={speed}
          autoplay={autoPlayAgs}
          spaceBetween={spaceBetweenMap[spaceBetween]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          grid={{
            rows,
            fill: "row",
          }}
          onInit={(swiper) => {
            setIsStart(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          onSlideChange={(swiper) => {
            setIsStart(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          slidesPerView={slidesPerView}
          breakpoints={swiperBreakpoints()}
          modules={[ Grid, Pagination, Navigation, Autoplay ]}
          pagination={paginationArgs}
          loop={loop}
          centeredSlides={centeredSlides}
          loopAdditionalSlides={4}
        >
          { children }

          { showNavigation && (
            <>
              <div className={`absolute z-20 ${(isEnd && isStart) && "hidden"} ${navigationArgs.leftIconClassName}`}>
                <IconButton
                  icon={navigationArgs.leftIcon}
                  size={navigationArgs.iconSize}
                  iconClassName={navigationArgs.iconWrapperClassName}
                  label="Go to previous slide"
                  onClick={() => swiperRef.current?.slidePrev()}
                  disabled={!!(isStart && !loop)}
                  color={isStart && !loop ? "iconDisabled" : "default"}
                />
              </div>
              <div className={`absolute bottom-0 z-20 ${(isEnd && isStart) && "hidden"} ${navigationArgs.rightIconClassName}`}>
                <IconButton
                  icon={navigationArgs.rightIcon}
                  size={navigationArgs.iconSize}
                  iconClassName={navigationArgs.iconWrapperClassName}
                  label="Go to next slide"
                  onClick={() => swiperRef.current?.slideNext()}
                  disabled={!!(isEnd && !loop)}
                  color={isEnd && !loop ? "iconDisabled" : "default"}
                />
              </div>
              { navigationStyle === "default" && (
                <Spacer size="2xl" />
              ) }
              { (navigationStyle === "tertiary" && slideCount > 1) && (
                <Spacer size="xl" />
              ) }
            </>
          ) }
        </Swiper>
      ) }
    </div>
  );
};

export default Carousel;
