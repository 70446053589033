"use client";

import {
  FC,
  useEffect,
  useMemo,
  useState,
  useCallback,
  createContext,
  ReactNode,
} from "react";
import { useCookies } from "react-cookie";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useRouter } from "next/navigation";
import { CookieSetOptions } from "universal-cookie";
import { usePostHog } from "posthog-js/react";

import type {
  AccessToken,
  ILoginSuccessResponse,
  RefreshToken,
} from "@auth/types";
import { AuthSDK } from "@auth/client-sdk";
import { useUserStore } from "libs/state/src/lib/stores/useUserStore";
import { AUTH_COOKIES } from "@/constants/auth";

import { useDatadog } from "./useDatadog";

export interface IAuthUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  id?: string;
  /** Old ID of the user */
  legacyId?: string;
  /** If this is an impersonation, then the ID of the user being impersonated */
  impersonationId?: string;
}

export interface AuthContext {
  /** True if the user is logged in, false if the user is not logged in,
   * undefined if whether the user is logged has not yet been determined
   * (auth initializing) */
  loggedIn?: boolean;
  login(email: string, password: string, rememberMe?: boolean, returnTo?: string): Promise<void>;
  sendMagicLink: AuthSDK["sendMagicLink"];
  setLoginTokens(tokens: ILoginSuccessResponse, rememberMe?: boolean): void;
  accessToken?: AccessToken;
  /* @deprecated Should use `user.firstName` */
  firstName?: string;
  /* @deprecated Should use `user.lastName` */
  lastName?: string;
  /* @deprecated Should use `user.email` */
  email?: string;
  /* @deprecated Should use `user.id` */
  id?: string;
  /* @deprecated Should use `user.legacyId` */
  old?: string;
  /* @deprecated Should use `user.impersonationId` */
  iold?: string;
  user?: IAuthUser;
  doAccessTokenRefresh(force: boolean): Promise<void>;
  logout(): void;
  forceLogin(tokenData: ILoginSuccessResponse | undefined, rememberMe?: boolean): Promise<void>;
}

export const authContext = createContext<AuthContext>(undefined as unknown as AuthContext);

export const LOCAL_STORAGE_REFRESH_TOKEN_NAME = "refreshToken";

export function cookieDomainSettings(
  cookieDomain: string,
  expires?: dayjs.Dayjs | undefined,
  maxAge?: number | undefined,
): CookieSetOptions {
  const secure = cookieDomain !== "localhost";
  return {
    path: "/",
    domain: cookieDomain,
    secure,
    sameSite: secure ? "none" : "strict",
    expires: expires?.toDate(),
    maxAge,
  };
}

export interface AuthConfig {
  AUTH_API_BASE_URL: string,
  NEXT_PUBLIC_COOKIE_DOMAIN: string,
}
export interface IAuthContextProps {
  children?: ReactNode | ReactNode[];
  authConfig: AuthConfig;
  storedAccessToken?: AccessToken;
}

/** This is a copy of AuthProvider, but slightly updated to
  * use useRouter from next13's app dir instead of the pages dir
  */
export const AuthProvider: FC<IAuthContextProps> = ({
  children,
  authConfig,
  storedAccessToken,
}) => {
  const [ refreshToken, setRefreshToken ] = useState<RefreshToken | undefined>();
  const [ accessToken, setAccessToken ] = useState<AccessToken | undefined>(storedAccessToken);
  const [ hasSetStoredToken, setHasSetStoredToken ] = useState(false);
  const { unsetUserDetails } = useUserStore();
  const datadog = useDatadog();
  const posthog = usePostHog();

  const [ cookies, setCookie, removeCookie ] = useCookies(Object.values(AUTH_COOKIES));

  const loggedIn = useMemo(() => !!accessToken, [ accessToken ]);
  const cookieAccessToken = useMemo(() => (hasSetStoredToken ?
    cookies[AUTH_COOKIES.ACCESS_TOKEN] :
    storedAccessToken), [
    accessToken, hasSetStoredToken, storedAccessToken,
  ]);

  useEffect(() => {
    if (storedAccessToken) {
      const decodedToken = AuthSDK.decode(storedAccessToken);
      setCookie(
        AUTH_COOKIES.ACCESS_TOKEN,
        storedAccessToken,
        cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN, undefined, decodedToken.exp || 10 * 60),
      );
    }
    setHasSetStoredToken(true);
  }, []);

  const identifiers = useMemo(() => {
    if (!accessToken) return {};

    try {
      const parsed = AuthSDK.decode(accessToken);
      datadog?.setGlobalContextProperty("cx_user", parsed);
      if (parsed.email) datadog?.setGlobalContextProperty("kk_email", parsed.email);
      if (parsed.iold || parsed.old) datadog?.setGlobalContextProperty("kk_customer_id", parsed.iold || parsed.old);
      if (parsed.sub) datadog?.setGlobalContextProperty("token_sub", parsed.sub);
      return {
        email: parsed.email,
        firstName: parsed.firstName,
        lastName: parsed.lastName,
        id: parsed.sub,
        iold: parsed.iold,
        old: parsed.old,
      };
    } catch (e) {
      console.error("failed to parse accessToken", e);
      return {};
    }

  }, [ accessToken ]);

  const onLogout = useCallback(() => {
    posthog.reset();
    datadog.clearGlobalContext();
  }, [ posthog, datadog ]);

  const auth = useMemo(() => new AuthSDK(
    authConfig.AUTH_API_BASE_URL,
  ), [ authConfig.AUTH_API_BASE_URL ]);

  const router = useRouter();

  const setLoginTokens = useCallback((res: ILoginSuccessResponse, rememberMe = true) => {
    setRefreshToken(res.refresh_token);
    setAccessToken(res.access_token);
    setCookie(
      AUTH_COOKIES.ACCESS_TOKEN,
      res.access_token,
      cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN, undefined, res.expires_in || 10 * 60),
    );
    if (rememberMe) {
      setCookie(
        AUTH_COOKIES.LOCAL_STORAGE_REFRESH_TOKEN_NAME,
        res.refresh_token,
        cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN, dayjs().add(1, "year")),
      );
      // localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME, res.refresh_token);
    }

    const parsed = AuthSDK.decode(res.access_token);
    if (parsed.old) {
      setCookie(
        AUTH_COOKIES.kk_customer_id,
        parsed.old,
        cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN, undefined, res.expires_in || 10 * 60),
      );
    } else {
      removeCookie(AUTH_COOKIES.kk_customer_id, cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN));
    }
  }, [ setCookie, removeCookie ]);

  const login = useCallback(async (email: string, password: string, rememberMe = false, returnTo?: string) => {
    const res = await auth.loginWithEmailPassword(email.toLowerCase(), password);
    setLoginTokens(res, rememberMe);
    void router.push(returnTo || "/");
  }, [ auth, setLoginTokens, router ]);

  const sendMagicLink = useCallback(
    async (
      email: string,
      redirectTo?: string,
    ) => auth.sendMagicLink(email.toLowerCase(), redirectTo),
    [ auth ],
  );

  const clearLoginTokens = useCallback(() => {
    setRefreshToken(undefined);
    setAccessToken(undefined);
    removeCookie(AUTH_COOKIES.ACCESS_TOKEN, cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN));
    removeCookie(AUTH_COOKIES.kk_customer_id, cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN));
    removeCookie(AUTH_COOKIES.LOCAL_STORAGE_REFRESH_TOKEN_NAME);
    // localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
    unsetUserDetails();

  }, [ removeCookie ]);

  const logout = useCallback(() => {
    clearLoginTokens();
    if (onLogout) onLogout();
    void router.push("/login");
  }, [ clearLoginTokens, router, onLogout ]);

  const doAccessTokenRefresh = useCallback(async (initial: boolean) => {
    const localStorageRefreshToken = cookies[AUTH_COOKIES.LOCAL_STORAGE_REFRESH_TOKEN_NAME];
    setAccessToken(cookieAccessToken);
    setRefreshToken(localStorageRefreshToken ?? undefined); // I actually don't think we need to do this, but no harm in it

    if (localStorageRefreshToken && (!cookieAccessToken || !initial)) {
      try {
        const res = await auth.getAccessTokenFromRefreshToken(localStorageRefreshToken);
        setAccessToken(res.access_token);
        setCookie(
          AUTH_COOKIES.ACCESS_TOKEN,
          res.access_token,
          cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN, undefined, res.expires_in),
        );

        const parsed = AuthSDK.decode(res.access_token);
        if (parsed.old) {
          setCookie(
            AUTH_COOKIES.kk_customer_id,
            parsed.old,
            cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN, undefined, res.expires_in || 10 * 60),
          );
        } else {
          removeCookie(AUTH_COOKIES.kk_customer_id, cookieDomainSettings(authConfig.NEXT_PUBLIC_COOKIE_DOMAIN));
        }
      } catch (e) {
        if ((e as AxiosError)?.response?.status === 401) {
          logout();
        }
      }
    } else if (!localStorageRefreshToken && !initial) {
      // If they don't have a refresh token, and it's not the initial check, log them out
      logout();
    }
  }, [ cookies, auth, setCookie, logout, removeCookie ]);

  const forceLogin = useCallback(async (
    tokenData: ILoginSuccessResponse | undefined,
    rememberMe = true,
  ) => {
    clearLoginTokens();
    if (tokenData) {
      setLoginTokens(tokenData, rememberMe);
    }
  }, [ clearLoginTokens, setLoginTokens ]);

  // Refresh access token every 15 minutes
  useEffect(() => {
    if (loggedIn === undefined) {
      void doAccessTokenRefresh(true);
    }

    let timeout: number | null = null;
    let interval: number | null = null;
    if (cookieAccessToken) {
      const tokenData = AuthSDK.decode(cookieAccessToken);
      if (tokenData.exp) {
        const now = dayjs().unix();
        const refreshIn = Math.min(tokenData.exp - now - 60, 60 * 15);
        if (refreshIn > 0) {
          timeout = window.setTimeout(
            () => {
              void doAccessTokenRefresh(false);
              interval = window.setInterval(
                () => {
                  void doAccessTokenRefresh(false);
                },
                1000 * 15 * 60, // Every 15 minutes
              );
            },
            1000 * refreshIn,
          );
        } else {
          void doAccessTokenRefresh(false);
        }
      }
    }
    if (timeout === null) {
      interval = window.setInterval(
        () => {
          void doAccessTokenRefresh(true);
        },
        1000 * 15 * 60, // Every 15 minutes
      );
    }

    return () => {
      if (interval !== null) {
        window.clearInterval(interval);
      }
      if (timeout !== null) {
        window.clearTimeout(timeout);
      }
    };

  }, [ loggedIn, cookies, refreshToken, doAccessTokenRefresh, cookieAccessToken ]);

  const user = useMemo((): IAuthUser | undefined => {
    if (!accessToken) return;
    const parsedToken = AuthSDK.decode(accessToken);
    return {
      email: parsedToken?.email,
      firstName: parsedToken?.firstName,
      lastName: parsedToken?.lastName,
      id: parsedToken?.sub,
      impersonationId: parsedToken?.iold,
      legacyId: parsedToken?.old,
    };
  }, [ accessToken ]);

  const value = useMemo(() => ({
    loggedIn,
    login,
    sendMagicLink,
    accessToken,
    doAccessTokenRefresh,
    setLoginTokens,
    logout,
    forceLogin,
    email: identifiers.email,
    firstName: identifiers.firstName,
    lastName: identifiers.lastName,
    id: identifiers.id,
    iold: identifiers.iold,
    old: identifiers.old,
    user,
  }), [
    accessToken,
    doAccessTokenRefresh,
    forceLogin,
    loggedIn,
    login,
    logout,
    identifiers.email,
    identifiers.firstName,
    identifiers.lastName,
    identifiers.iold,
    identifiers.old,
    identifiers.id,
    sendMagicLink,
    setLoginTokens,
    user,
  ]);

  return (
    <authContext.Provider value={ value }>
      { children }
    </authContext.Provider>
  );
};
