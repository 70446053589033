import { PublicConfig } from "apps/website/config/public";

import { IHeaderLink } from "../../base/Button/Button.map";

export interface IHeaderAuth {
  firstName: string;
  lastName?: string;
}

export interface IHeaderHistoryNavigation {
  hide?: boolean;
  back?: () => void;
  forward?: () => void;
}

export interface IHeaderLinks {
  primaryLinks: IHeaderLink[];
  secondaryLinks: IHeaderLink[];
  authLinks: IHeaderLink[];
  guestLinks: IHeaderLink[];
}

export const HEADER_LINKS_NONE: IHeaderLinks = {
  primaryLinks: [],
  secondaryLinks: [],
  authLinks: [],
  guestLinks: [],
};

export const HEADER_LINKS_DEFAULT: IHeaderLinks = {
  primaryLinks: [
    {
      id: "p-0",
      name: "Our products",
      to: "/products",
      children: [
        {
          id: "p-0.1",
          name: "Fresh food and treats",
          to: "/products",
          image: {
            src: "/images/dev/components/Header/sub-nav-products-fresh.webp",
            width: 310,
            height: 310,
          },
        },
        {
          id: "p-0.2",
          name: "Scoop litter",
          to: "/scoop-litter",
          tag: "New!",
          image: {
            src: "/images/dev/components/Header/sub-nav-products-scoop.webp",
            width: 310,
            height: 310,
          },
        },
      ],
    },
    {
      id: "p-1",
      name: "Why fresh",
      to: "/why-fresh",
    },
    { id: "p-2", name: "About us", to: "/about-us" },
    { id: "p-3", name: "Learn", to: "/articles" },
  ],
  secondaryLinks: [],
  authLinks: [
    { id: "a-0", name: "My Homepage", to: "/customer" },
    { id: "a-1", name: "Manage my plan", to: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/manage-plan` },
    { id: "a-2", name: "My details", to: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/parent-details` },
    { id: "a-3", name: "Refer a friend", to: "/refer-a-friend?situation=hamburger_link" },
    { id: "a-4", name: "Past orders", to: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/order-history` },
    { id: "a-5", name: "Help", to: "https://faq.katkin.com/" },
    { id: "a-6", name: "Logout", to: "/logout", type: "logout" },
  ],
  guestLinks: [
    { id: "g-0", name: "Login", to: "/login" },
    { id: "g-1", name: "Start my trial", to: "/sign-up", type: "cta", withAssociatedDiscount: true },
  ],
};

export const HEADER_LINKS_LOGGED_IN: IHeaderLinks = {
  primaryLinks: [
    { id: "p-0", name: "Home", to: "/customer" },
    // { id: "p-1", name: "Deliveries", to: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}order-history` },
    { id: "p-2", name: "My Plans", to: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/manage-plan` },
    { id: "p-3", name: "My Cats", to: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/pet-details` },
    { id: "p-4", name: "My Details", to: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/parent-details` },
    { id: "p-5", name: "Past orders", to: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/order-history` },
    { id: "p-6", name: "Learn", to: "/articles" },
  ],
  secondaryLinks: [
    { id: "s-1", name: "Gift a box", to: "/refer-a-friend" },
    { id: "s-2", name: "Help", to: "/help" },
    { id: "s-3", name: "Logout", to: "/logout" },
  ],
  authLinks: [],
  guestLinks: [],
};

export const HEADER_LINKS_BREEDER: IHeaderLinks = {
  ...HEADER_LINKS_DEFAULT,
  authLinks: [
    { id: "a-0", name: "Send a box", to: "/forms/gccf-new-kitten" },
    { id: "a-1", name: "Help", to: "https://faq.katkin.com/" },
    { id: "a-2", name: "Logout", to: "/logout", type: "logout" },
    { id: "a-3", name: "Send a box", to: "/forms/gccf-new-kitten", type: "cta" },
  ],
  guestLinks: [
    { id: "g-0", name: "Login", to: "/login" },
    { id: "g-1", name: "Register", to: "/forms/new-breeder", type: "cta" },
  ],
};
