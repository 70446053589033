"use client";

import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter, useSearchParams } from "next/navigation";

import { DisplayState } from "@/constants/state";
import { IFreshSubDiscountCalculations } from "@/discount-calculation";
import { useAPI } from "apps/website/hooks/useAPI";
import { useAuth, useDatadog } from "@auth/client-sdk-react";
import { useNewFormsServiceStore } from "@./state";

import Section from "../../layout/Section/Section";
import Column from "../../layout/Column/Column";
import Grid from "../../layout/Grid/Grid";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";
import Container from "../../layout/Container/Container";
import SectionLoadingSpinner from "../SectionLoadingSpinner/SectionLoadingSpinner";
import Icon from "../../base/Icon/Icon";

import { ErrorMessage } from "./Error";
import ApplyDiscountButton from "./ApplyDiscountButton";

export interface ISectionCancellationDiscountProps {
  discountCode: string
}

export interface discountTextProps {
  reason?: string;
  discounts?: IFreshSubDiscountCalculations;
  pronoun?: string;
  catName?: string
}

const DiscountText: FC<discountTextProps> = ({ reason, discounts, pronoun, catName }) => (
  <>
    { reason === "NOT_EATING" && (
      <>
        <TextSubtitle size={2} align={{ initial: "default", lg: "default" }}>
          Give { catName?.toString() } time to get back on track
        </TextSubtitle>

        <TextBody tag='div' size={2} align={{ initial: "default", lg: "default" }}>
          <p>
            We get it. It&apos;s hard when { pronoun } doesn&apos;t devour { pronoun === "he" ? "his" : "her" } food.
          </p>
          <p>
            But we know you want the best for { pronoun === "he" ? "him" : "her" }.
            And so do we. So here&apos;s { discounts?.percentageSaved }% off your next box.
            (That&apos;s a £{ discounts?.savings?.toFixed(2) } saving.)
          </p>
          <p>
            Because we&apos;re beside you on the journey to { catName?.toString() }&apos;s best health.
          </p>
        </TextBody>
      </>
    )
    }
    { reason === "TOO_EXPENSIVE" && (<>
      <TextSubtitle size={2} align={{ initial: "default", lg: "default" }}>
        { catName } deserves the best.
      </TextSubtitle>
      <TextBody tag='div' size={2} align={{ initial: "default", lg: "default" }}>

        <p>
          But we understand. So take { discounts?.percentageSaved }% off { pronoun === "he" ? "his" : "her" } next box.
          So { pronoun } gets the real, nutritious meat { pronoun === "he" ? "he" : "she" } was born to eat.
          And you save £{ discounts?.savings?.toFixed(2) }. Win win.
        </p>

      </TextBody>
    </>
    )
    }
    { reason === "SUB_DOESNT_WORK" && (
      <>
        <TextSubtitle size={2} align={{ initial: "default", lg: "default" }}>
          Nutritious meat for { catName }. Convenient deliveries for you.
        </TextSubtitle>

        <TextBody tag="div" size={2} align={{ initial: "default", lg: "default" }}>
          <p>
            You can have it all - with { discounts?.percentageSaved }% off your next box
            (that&apos;s a £{ discounts?.savings?.toFixed(2) } discount)
            to really get your claws into our flexible subscriptions.
            We want to make it easy for { catName } to get the fresh food { pronoun } deserves.
          </p>

        </TextBody>
      </>
    )
    }
  </>
);

const SectionCancellationDiscount: FC<ISectionCancellationDiscountProps> = ({ discountCode }) => {

  const api = useAPI();
  const { accessToken } = useAuth();

  const [ discounts, setDiscounts ] = useState<IFreshSubDiscountCalculations>();
  const [ formState, setFormState ] = useState<DisplayState>(
    DisplayState.LOADING,
  );

  const datadog = useDatadog();
  const router = useRouter();
  const params = useSearchParams();
  const { getActiveFieldValue } = useNewFormsServiceStore();

  const flowId = "cancellation";

  const reason = useMemo<string | undefined>(() => getActiveFieldValue(flowId, "cancellationPrimary")?.data.submitValue?.toString(), []);

  const catName = useMemo<string | undefined>(() => getActiveFieldValue(flowId, "catName")?.data.submitValue?.toString(), []);

  const catSex = useMemo<string | undefined>(() => getActiveFieldValue(flowId, "catSex")?.data.submitValue?.toString(), []);

  const pronoun = useMemo(() => (catSex === "BOY" ? "he" : "she"), [ catSex ]);

  if (!discountCode) {
    setFormState(DisplayState.ERROR);
  }

  const getDiscountCalculations = useCallback(async ():

  Promise<IFreshSubDiscountCalculations | undefined> => {

    const subId = params.get("subscriptionId");

    if (!subId || Number.isNaN(+(subId))) {
      void router.push("/discounts/failed");
      return;
    }

    const { data } = await api.Discounts.getDiscountCalculations(+subId, discountCode);
    if (!data) { throw new Error(); }
    return data;

  }, [ accessToken, discountCode, params ]);

  const getDiscountEstimation = async () => {
    try {
      const result = await getDiscountCalculations();
      if (result) {

        datadog.logger.info("adsfa", result);
        setDiscounts(result);
        setFormState(DisplayState.READY);
      }

    } catch (error) {
      console.log("error", error);
      setFormState(DisplayState.ERROR);
    }
  };

  useEffect(() => {
    if (accessToken) {
      void getDiscountEstimation();
    }
  }, [ accessToken ]);

  const getTitleText = (): string => {

    if (reason === "TOO_EXPENSIVE" || reason === "SUB_DOESNT_WORK") {
      return `Exclusive offer: ${discounts?.percentageSaved}% off next box`;
    }
    return `Get ${discounts?.percentageSaved}% off your next box`;

  };

  return (

    <div className="p-2">
      { formState === DisplayState.LOADING ? (
        <SectionLoadingSpinner data-testid="loading-spinner" theme="light-grey" />
      ) :
        <Section component={SectionCancellationDiscount.name} >
          <Container padding="none" size="md" >
            <TextSubtitle size={1} align="center">
              { getTitleText() }
            </TextSubtitle>

            <Spacer size={"md"}></Spacer>
            <div className="flex justify-center space-x-1">
              <TextBody size={1} align="center" className="line-through text-dark-grey" tag="span" >
                £{ discounts?.originalPrice?.toFixed(2) }
              </TextBody>
              <TextBody size={1} align="center" tag="span">
                £{ discounts?.discountedPrice?.toFixed(2) }
              </TextBody>
            </div>
          </Container>

          <Spacer size={"lg"}></Spacer>

          <Container padding="none" className="bg-white " size="sm" >
            <Grid theme="light-grey">
              <div className="flex gap-2 col-span-12 justify-center p-2">
                <Column spans={{ default: 2, lg: 6 }}>
                  <Icon icon="coins" className="w-16 h-16 lg:w-20 lg:h-20"></Icon>

                </Column>
                <Column spans={{ default: 10, lg: 6 }} className="gap-y-2" >
                  <DiscountText
                    reason={reason ?? undefined}
                    catName={catName ?? undefined}
                    discounts={discounts}
                    pronoun={pronoun} />
                </Column>
              </div>
            </Grid>

          </Container>
          <Spacer />
          <ApplyDiscountButton
            discountPercentage={`${discounts?.percentageSaved}`}
            discountCode={discountCode}
            catName={catName ?? undefined}
            catSex={catSex ?? undefined} />
          { formState === DisplayState.ERROR && <ErrorMessage data-testid="error-message" /> }
        </Section>
      }</div>
  );
};

export default SectionCancellationDiscount;
