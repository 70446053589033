import { PublicConfig } from "apps/website/config/public";

import {
  TMenuMap,
  EMenuItemStyle,
  EMenuItemType,
  EMenu,
  IMenuDetails,
  EMenuState,
} from "./usePopoverMenuStore.types";

export const INITIAL_STATE: IMenuDetails = {
  state: EMenuState.CLOSED,
  title: "",
  items: [],
};

export const MENUS: TMenuMap = {
  [EMenu.MANAGE_FRESH_PLAN]: {
    title: "Your plan. your way",
    items: [
      {
        type: EMenuItemType.LINK,
        title: "Edit next delivery date",
        description: "Change when your next fresh food box arrives.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/manage-plan/delay`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Edit delivery frequency",
        description: "Change how often you receive fresh food boxes.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/fresh/delivery-frequency/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: "Update portion sizes",
        description: "Increase or decrease daily calories.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/fresh/portion-size/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: "Change cat details",
        description: "Update info like age, weight + allergies.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/pet-details`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Amend recipe selection",
        description: "Mix up recipes. Or try something new.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/change-recipes`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Manage extras",
        description: "Add tasty treats + meal toppers.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/change-addons`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Thinking of cancelling?",
        style: EMenuItemStyle.BASIC,
        url: "/forms/pre-cancellation",
      },
    ] as const,
  },
  [EMenu.MANAGE_SCOOP_HEALTH_PLAN]: {
    title: "Need to make a change?",
    items: [
      {
        type: EMenuItemType.LINK,
        title: "Edit next delivery date",
        description: "Change when your next Scoop Health litter arrives.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/manage-plan/delay`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Change delivery frequency",
        description: "Change how often you get litter.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/scoop/health/delivery-frequency/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: "Thinking of cancelling?",
        style: EMenuItemStyle.BASIC,
        url: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/pre-cancellation-scoop-health`,
      },
    ] as const,
  },
  [EMenu.MANAGE_SCOOP_PLANET_PLAN]: {
    title: "Your plan. your way.",
    items: [
      {
        type: EMenuItemType.LINK,
        title: "Edit next delivery date",
        description: "Change when your next Scoop Planet litter arrives.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/manage-plan/delay`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Change delivery frequency",
        description: "Change how often you get litter.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/scoop/planet/delivery-frequency/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: "Thinking of cancelling?",
        style: EMenuItemStyle.BASIC,
        url: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/pre-cancellation-scoop-planet`,
      },
    ] as const,
  },
} as const;
