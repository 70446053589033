"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useMemo,
} from "react";

import { useAuth } from "@auth/client-sdk-react";

import useGetClient, { IUseGetClient } from "../hooks/useGetClient";

export interface ApiClientsContext extends IUseGetClient {
  tokenStatus: {
    accessToken: string | undefined;
    loggedIn: boolean | undefined;
  };
}

export const apiContext = createContext<ApiClientsContext>(undefined as unknown as ApiClientsContext);

// Site-wide context for customer data (subscriptions, cats, etc.)
export const KatkinCustomerApiProvider: FC<PropsWithChildren> = ({ children }) => {

  const { accessToken, loggedIn } = useAuth();
  const getClient = useGetClient;

  const clients = useMemo(() => (getClient(accessToken)), [ loggedIn, accessToken ]);

  return (
    <apiContext.Provider value={clients}>
      { children }
    </apiContext.Provider>
  );
};
