import sanitizeHtml from "sanitize-html";

interface IHighlightTextWithHTML {
  text: string;
}

/**
 * Processes a text string to apply highlighting using HTML.
 * @param {string} text The input text string.
 * @returns {string} The processed HTML-safe string with highlights.
 * @notes The function uses the backtick character (`) as the default indicator to toggle highlighting.
 */

const highlightTextWithHTML = ({ text }: IHighlightTextWithHTML): string => {
  const sanitizedText = sanitizeHtml(text);
  const highlightIndicator = "`";
  const spaceCharacter = "&nbsp;<wbr/>";

  // Replace spaces with a non-breaking space character
  const splitText = sanitizedText.split("").map((char) => (char === " " ? spaceCharacter : char));

  const wrappedText: string[] = [];

  let shouldHighlight = false; // Default to unhighlighted.

  for (let i = 0; i < splitText.length; i += 1) {
    const currentChar = splitText[i];
    if (currentChar === highlightIndicator) {
      // Toggle the highlight state and skip the indicator itself
      shouldHighlight = !shouldHighlight;
      continue;

    }

    const isSpace = currentChar === spaceCharacter;
    const wrappedChar = shouldHighlight
      ? `<span class='inline-block relative before:z-0 before:absolute before:left-0 before:top-0 before:bg-brand before:block before:w-full before:h-full'><span class='relative'>${
        isSpace ? "&nbsp;" : currentChar
      }</span>${isSpace ? "</span><wbr/>" : "</span>"}`
      : currentChar;

    wrappedText.push(wrappedChar);
  }

  return wrappedText.join("");
};

export default highlightTextWithHTML;
