import { Dayjs } from "dayjs";
import { BaseAPI } from "@utils/base-api";
import {
  PouchSize,
} from "libs/cat-calculations/src/lib/cat-calculations.types";
import { HttpException } from "@nestjs/common";

import { EStatusCodes } from "@/constants/state";
import { DiscountApplication } from "@/primary-models";

import { TRequest } from "../../../api.types";

import {
  IMySubscriptions,
  ActivateSubscription,
  SubscriptionStatus,
  OrderType,
  SubscriptionType,
  IDelaySuccessResult,
  ICustomerOrder,
  IDiscount,
} from "./types/subscriptions";
import {
  IPossibleDeliveryDate,
  IPossibleDeliveryDates,
} from "./types/delivery-dates";
import { ExtraProduct } from "./types/products";
import { ICustomerCatsResponse, MealPlanType } from "./types/cats";
import { ICustomer } from "./types";

export class CustomerAPI extends BaseAPI {

  public async getDiscountCode(): Promise<string> {
    try {
      const data = await this.getAllSubscriptions();
      return data?.discountData?.discount?.code ?? "";
    } catch (error) {
      console.error(error);
    }
    return "";
  }

  public async getDiscountData(): Promise<IDiscount | undefined> {
    const data = await this.getAllSubscriptions();
    return data?.discountData?.discount;
  }

  public async getFullCustomer() {
    return this.axios.get("/customer/full");
  }

  public async getCustomer(): Promise<ICustomer> {
    const result = await this.getFullCustomer();
    return result.data.customer;
  }

  public async getAllSubscriptions({
    show2404Product = false,
  }:{
    show2404Product?: boolean
  } = {}): Promise<IMySubscriptions> {
    const result = await this.axios.get(
      "/customer/getAllSubscriptions",
      { params: {
        show2404Product,
      } },
    );
    return result.data;
  }

  public async reactivateSubscription(
    subscriptionId: string | number,
    deliveryDate: Dayjs,
  ): Promise<ActivateSubscription> {
    const result = await this.axios.post("/customer/subscriptions", {
      rechargeSubscriptionIds: [ Number(subscriptionId) ],
      nextDeliveryDate: deliveryDate.format("YYYY-MM-DD"),
    });
    return result.data;
  }

  public async addDiscountCode(
    discountCode?: string,
  ): Promise<DiscountApplication> {
    if (discountCode === undefined) throw new HttpException({ message: "No discount code" }, EStatusCodes.NOT_ACCEPTABLE);

    try {
      const response = await this.axios.post(
        `/customer/discount/add?discountCode=${encodeURIComponent(discountCode)}`,
        undefined,
      );
      return response.data;
    } catch {
      throw new HttpException({ message: "Bad request" }, EStatusCodes.BAD_REQUEST);
    }

  }

  public async removeDiscountCode(): Promise<void> {
    await this.axios.post(
      "/customer/discount/remove",
      undefined,
    );
  }

  public async getPossibleSubscriptions(): Promise<void> {
    const result = await this.axios.get(
      "/customer/extraPlan/getPossibleSubscriptions",
    );
    return result.data;
  }

  /** @param numberOfDays
   *  @param subscriptionIds
   *  @param subscriptionStatus if set, then only subscriptions in that state will be considered when determining the
   *  subscriptionType and addonPlanType to use when searching for matching rules.
   *  @param bankHoliday should be false in most cases (unless maybe you're an admin), since we want customers to only
   *  be able to choose from non-disabled delivery dates regardless of whether or not they've been marked as bank
   *  holidays
   */
  public async getPossibleDeliveryDatesSub(
    numberOfDays: number | undefined,
    subscriptionIds: number[] | undefined,
    subscriptionStatus: SubscriptionStatus | undefined,
    bankHoliday: boolean | undefined,
  ): Promise<IPossibleDeliveryDates> {
    const params = new URLSearchParams();
    if (numberOfDays) params.set("numberOfDays", numberOfDays.toString());
    if (subscriptionIds) subscriptionIds.forEach((subscriptionId) => params.append("subscriptionId", subscriptionId.toString()));
    if (subscriptionStatus) params.set("subscriptionStatus", subscriptionStatus);
    if (bankHoliday) params.set("bankHoliday", "true");
    const result = await this.axios.get("/customer/getPossibleDeliveryDatesSub", {
      params,
    });
    return result.data;
  }

  /** If a parameter is not set, then it is treated as if all the types apply.
   *  So if 2022-12-06 is blocked for fresh and 2022-12-07 is blocked for addon and
   *  subscriptionType is not set, then neither 2022-12-06 nor 2022-12-07 will be
   *  available in the returned data.
   *  @param orderType
   *  @param numberOfDays
   *  @param subscriptionType
   *  @param addonPlanType
   *  @param bankHoliday if set to true, then only bank holidays will be excluded. Else
   *  non-bank holiday delivery dates that have been disabled will be excluded as well.
   *  Should probably be false for most use cases. */
  public async getPossibleDeliveryDatesType(
    orderType: OrderType,
    numberOfDays?: number,
    subscriptionType?: SubscriptionType,
    addonPlanType?: ExtraProduct,
    bankHoliday?: boolean,
  ): Promise<IPossibleDeliveryDate> {
    const params = new URLSearchParams();
    params.set("orderType", orderType);
    if (numberOfDays) params.set("numberOfDays", numberOfDays.toString());
    if (subscriptionType) params.set("subscriptionType", subscriptionType);
    if (addonPlanType) params.set("addonPlanType", addonPlanType);
    if (bankHoliday) params.set("bankHoliday", "true");
    const result = await this.axios.get("/getPossibleDeliveryDatesType", { params });
    return result.data;
  }

  public async delay(
    newDeliveryDate: Dayjs,
    mainSubscriptionIds: number[] | undefined,
    reason: unknown, // TODO type this properly
  ): Promise<IDelaySuccessResult> {
    const result = await this.axios.post("/customer/charges/next/date", {
      newDeliveryDate: newDeliveryDate.format("YYYY-MM-DD"),
      mainSubscriptionIds,
      reason,
    }, {
    });
    return result.data;
  }

  public async getOrders(
    subscriptionId: string | undefined,
    catId: string | undefined,
    catName: string | undefined,
  ): Promise<ICustomerOrder[]> {
    const params = new URLSearchParams();
    if (subscriptionId) params.set("subscriptionId", subscriptionId.toString());
    if (catId) params.set("catId", catId);
    if (catName) params.set("catName", catName);
    const result = await this.axios.get("/customer/orders/all", {
      params,
    });
    return result.data;
  }

  public async getCats() {
    const response = await this.axios.get<ICustomerCatsResponse>(
      "/customer/cats?usable=true",
    );
    return response.data;
  }

  public async visitedPage(
    pageUrl: string,
    token: string,
  ): Promise<unknown> {
    const result = await this.axios.post(
      "/customer/visitedPage",
      { pageUrl },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );
    return result.data;
  }

  public async changeMealPlan(req: ChangeMealPlanRequest): Promise<unknown> {
    const result = await this.axios.put(
      "/customer/mealPlan",
      req,
      {},
    );
    return result.data;
  }

  public async addNibblesTrial(request: AddNibblesTrialRequest): Promise<string> {
    const result = await this.axios.post(
      "/customer/addTrialNibblesWithRepeat",
      request,
      {},
    );
    return result.data;
  }

  public async getAvailableTraySizes(planType: MealPlanType): Promise<ITraySizeResource[]> {

    const result: TRequest<TMealPlanResponse> = await this.axios.get(
      "/availableMealPlans",
      {},
    );

    if (result.data?.[planType]?.traySize) {
      return Object.entries(result.data[planType].traySize)?.map(([ key, value ]) => ({
        traySize: key as PouchSize,
        ...value,
      }));
    }

    return [];

  }

  public async getAvailableMealPlanTypes(): Promise<IMealPlanTypeResource[]> {
    const result: TRequest<TMealPlanResponse> = await this.axios.get(
      "/availableMealPlans",
      {},
    );

    return Object.values(result.data);
  }

  public async updateFreshSizeAndCadence(req: UpdateFreshSizeAndCadenceRequest): Promise<unknown> {
    const result = await this.axios.post(
      "/customer/cats/mealPlan",
      req,
    );
    return result.data;

  }

}

export interface AddNibblesTrialRequest {
  productTitle: "NIBBLES_CHICKEN" | "NIBBLES_SALMON";
}

interface ChangeMealPlanRequest {
  mealPlan: "Plan28x28"
  | "Plan28x56"
  | "Plan14x14"
  | "PlanTrial14x14";
  reactivate?: boolean | null;
  catIDs?: string[] | null;
  /** Must be in YYYY-MM-DD format */
  deliveryDate?: string | null;
}

interface UpdateFreshSizeAndCadenceRequest {
  catId: string
  newMealPlan: MealPlanType;
  newSize: PouchSize;
  source: string
  cxUser?: string;
}

export interface ITraySizeResource {
  traySize?: PouchSize;
  calories: number;
  price: number;
}

export interface IMealPlanTypeResource {
  title: string;
  planType: MealPlanType;
  traySize: Record<PouchSize, ITraySizeResource>;
  quantity: number;
  cadence: number;
}

type TMealPlanResponse = Record<MealPlanType, IMealPlanTypeResource>;
