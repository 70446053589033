import { Breakpoint } from "apps/website/maps/Breakpoint.map";
import { Theme } from "apps/website/maps/Theme.map";

type BorderlessSectionWidth = {
  [key in Breakpoint]?: string;
};

export const borderlessSectionWidths: BorderlessSectionWidth = {
  default: "w-[calc(100vw-4rem)]",
  md: "md:w-[calc(100vw-4rem)]",
  lg: "lg:w-[calc(100vw-5rem)]",
  xl: "xl:w-[calc(100vw-6rem)]",
};

export const borderlessSectionBeforeWidths: BorderlessSectionWidth = {
  default: "before:w-[calc(100vw-4rem)]",
  lg: "lg:before:w-[calc(100vw-5rem)]",
  xl: "xl:before:w-[calc(100vw-6rem)]",
};

const borderlessSectionStyles = `relative before:h-full before:absolute before:top-0 before:left-1/2 before:transform before:-translate-x-1/2 before:z-[-1] ${borderlessSectionBeforeWidths.default} ${borderlessSectionBeforeWidths.lg} ${borderlessSectionBeforeWidths.xl}`;

export const borderlessSectionBackgroundClassMap: Record<Theme, string> = {
  default: `before:bg-transparent ${borderlessSectionStyles}`,
  dark: `before:bg-black ${borderlessSectionStyles}`,
  "dark-alt": `before:bg-black ${borderlessSectionStyles}`,
  light: `before:bg-white ${borderlessSectionStyles}`,
  brand: `before:bg-brand ${borderlessSectionStyles}`,
  "brand-alt": `before:bg-brand ${borderlessSectionStyles}`,
  color: `before:bg-pink-700 ${borderlessSectionStyles}`,
  header: `before:bg-white ${borderlessSectionStyles}`,
  footer: `before:bg-black ${borderlessSectionStyles}`,
  blue: `before:bg-blue ${borderlessSectionStyles}`,
  "light-blue": `before:bg-light-blue ${borderlessSectionStyles}`,
  pink: `before:bg-pink ${borderlessSectionStyles}`,
  orange: `before:bg-orange ${borderlessSectionStyles}`,
  green: `before:bg-green ${borderlessSectionStyles}`,
  "light-grey": `before:bg-light-grey ${borderlessSectionStyles}`,
  "form-input": `before:bg-transparent ${borderlessSectionStyles}`,
  "product-card": `before:bg-white ${borderlessSectionStyles}`,
  inherit: `before:bg-inherit ${borderlessSectionStyles}`,
};
